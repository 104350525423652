<template>
    <div class="card-list">
        <CardItem v-for="(item, index) in list" :key="index" :item="item" @choose="chooseHandler" />
    </div>
</template>
<script>
import CardItem from "../carditem"

export default {
    components: {
        CardItem
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    name: "CardList",
    data() {
        return {
            msg: '可查看README.md了解工程信息'
        };
    },
    activated() {
        // 使用keep-alive  此函数生效
    },
    methods: {
        chooseHandler(item) {
            this.$emit('choose', item)
        }
    },
};
</script>
<style lang="less" scoped>
.card-list {
    font-size: 16px;
    margin: 0 auto;
    width: 97%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 50%);
}
</style>