
import Home from '../view/Home'

const routers = [
   {
      path: '/',
      name: 'Home',
      component: Home
   },
   {
      path: '/CardDetails',
      name: 'CardDetails',
      component: () => import('../view/CardDetails')
   },
   {
      path: '/OrderList',
      name: 'OrderList',
      component: () => import('../view/OrderList')
   },
   {
      path: '/OrderConfirm',
      name: 'OrderConfirm',
      component: () => import('../view/OrderConfirm')
   },
   {
      path: '/OrderDetails',
      name: 'OrderDetails',
      component: () => import('../view/OrderDetails')
   },
   {
      path: '/ErrorClient',
      name: 'ErrorClient',
      component: () => import('../view/error-page/ErrorClient')
   },
   {
      path: '/CollectionList',
      name: 'CollectionList',
      component: () => import('../view/CollectionList')
   },
   {
      path: '/LoginPage',
      name: 'LoginPage',
      component: () => import('../view/LoginPage')
   },
   {
      path: '/BindPage',
      name: 'BindPage',
      component: () => import('../view/BindPage')
   },
   {
      path: '/SchollList',
      name: 'SchollList',
      component: () => import('../view/SchollList')
   },
   
]
export default routers;