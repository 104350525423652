
export default {
    common: state => state.common,
    cardDetails: state => state.cardDetails,
    cardId: state => state.cardId,
    sku: state => state.sku,
    userInfo: state => state.userInfo,
    orderDetails: state => state.orderDetails,
    orderId: state => state.orderId,
    buyHistory: state => state.buyHistory,
    recommendeds: state => state.recommendeds,
    errCodes: state => state.errCodes,
    curSchool: state => state.curSchool,
}