<template>
  <div class="home" id="home-page">
    <nar-bar :left-btn="leftBtn" :title="title" @leftHandler="leftHandler" />
    <!-- 支持滑动布局 -->
    <bottom-bar :btns="bottomBtns" :active-number="activeNumber" @active="btmActive" />
    <div v-show="activeNumber === 0" style="width:100%;height:60px"></div>
    <div v-show="activeNumber === 0" class="scoll-content-1">
      <card-list v-if="cardList.length > 0" :list="cardList" @choose="nextHandler" />
      <svg-icon v-if="cardList.length === 0" class="no-list-icon" icon-class="no-data" />
      <div v-if="cardList.length === 0" class="no-list">
        暂无数据
      </div>
    </div>
    <div v-show="activeNumber === 0" :style="{ width: '100%', height: '80px' }"></div>
    <my-info v-show="activeNumber !== 0" :userInfo="userInfo" @loginOut="loginOut" @next-page="nextPage" />
    <bottom-bar :btns="bottomBtns" :active-number="activeNumber" @active="btmActive" />
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import CardList from '../components/cardlist'
import NarBar from '../components/navbar'
import BottomBar from '../components/bottomBar'
import MyInfo from '../components/myInfo'
import { getSpusList, getUserInfo, errCodes } from '../util/api'

export default {
  components: {
    CardList,
    NarBar,
    BottomBar,
    MyInfo
  },
  name: "HomePage",
  data() {
    return {
      bh: '60px',
      leftBtn: {
        hidden: true,
        icon: 'back',
        text: ''
      },
      bottomBtns: [{
        index: 0,
        icon: 'shop',
        text: '商城',
      }, {
        index: 1,
        icon: 'my',
        text: '我的',
      }],
      activeNumber: 0,
      title: this.activeNumber === 1 ? '我的' : '卡样商城',
      cardList: [],
      current_page: 1,
      page_size: 10,
      pageParams: {}
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
  },
  activated() {
    try {
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault();
      });
    } catch (e) {
      console.log("🚀 ~ file: Home.vue:87 ~ activated ~ e", e)
    }

    this.$store.dispatch('updateBuyHistory', [])
    this.$store.dispatch('updateSku', {})
    this.$store.dispatch('updateCardId', '')
    this.$store.dispatch('updateCardDetails', {})
    this.$store.dispatch('updateRecommended', []);
    this.$store.dispatch('updateOrderID', '')
    this.pageParams = this.$route.params
    console.log("🚀 ~ file: Home.vue ~ line 74 ~ activated ~this.pageParams", this.pageParams)
    if (this.pageParams['number'] === 1) {
      this.activeNumber = 1;
      this.title = '我的'
    }
    errCodes().then((res) => {
      console.log("🚀 ~ file: Home.vue ~ line 73 ~ errCodes ~ res", res)
      this.$store.dispatch('updateErrCode', res['data'] ? res['data']['list'] : [])
    })
    getSpusList({
      current_page: this.current_page,
      page_size: this.page_size,
    }).then((res) => {
      if (res['code'] === 0) {
        const list = res['data'] ? res['data']['list'] : []
        this.cardList = list
      } else {
        this.$toast(res['message'])
      }
    }, (e) => {
      console.log(e)
    })

  },
  methods: {
    btmActive(item) {
      console.log("🚀 ~ file: Home.vue ~ line 86 ~ btmActive ~ item", item)
      if (item['index'] === 1) {
        const token = window.localStorage.getItem('LOGIN_TOKEN')
        console.log("🚀 ~ file: Home.vue ~ line 89 ~ btmActive ~ token", typeof token)
        if (token && token !== 'null') {
          getUserInfo().then((res) => {
            if (res['code'] === 0) {
              this.$store.dispatch('updateUserInfo', res['data'] ? res['data'] : {})
              if (res['data']['org_reserve_switch']) {
                window.localStorage.setItem('RESERVE_SWITCH', res['data']['org_reserve_switch'])
              }
              this.activeNumber = item['index']
              this.title = item['text']
              document.getElementById('home-page').scrollTop = 0
            } else {
              this.$toast(res['message'])
              if (res['code'] === 50008 || res['code'] === 50014) {
                this.$router.replace({
                  name: 'LoginPage', params: {
                    name: 'Home',
                    number: 0
                  }
                })
              }
            }

          })
        } else {
          this.$router.replace({
            name: 'LoginPage', params: {
              name: 'Home',
              number: item['index']
            }
          })
        }
        // 判断是否有token 如果没有token 去登录
      } else {
        this.activeNumber = item['index']
        this.title = '卡样商城'
        document.getElementById('home-page').scrollTop = 0
      }

    },
    leftHandler() {
      if (this.activeNumber === 1) {
        this.activeNumber = 0;
      } else {
        window.WeixinJSBridge.call('closeWindow');
      }
    },
    nextHandler(item) {
      this.$store.dispatch('updateCardId', item['id'])
      this.$router.replace({ name: 'CardDetails' })
    },
    loginOut() {
      console.log('退出/切换')
    },
    nextPage(type) {
      if (type === "order") {
        this.$router.replace('OrderList');
      } else {
        this.$router.replace('CollectionList');
      }
    }
  },
};
</script>
<style lang="less" scoped>
.home {
  background: #F5F5F5;
  font-size: 16px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;

  .not-wx {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #fff;
    top: 0;
    left: 0;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .nav-bar {
    width: 100%;
    height: 50px;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;

    .search-icon {
      cursor: pointer;
      font-size: 18px;
      vertical-align: middle;
    }
  }

  .bottom-action {
    width: 100%;
    height: 50px;
    background: red;
    position: fixed;
    z-index: 100;
    left: 0;
    bottom: 0;
  }

  .no-list-icon {
    position: absolute;
    top: 30%;
    width: 100%;
    text-align: center;
    font-size: 50px;
    font-weight: 400;
    color: #aaa;
    line-height: 10px;
  }

  .no-list {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
    font-size: 8px;
    font-weight: 400;
    color: #aaa;
    line-height: 10px;
  }
}
</style>