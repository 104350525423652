import types from './type'
export default {
    [types.UPDATE_SKU](state, data) {
        state.sku = data
        window.localStorage.setItem(types.UPDATE_SKU, JSON.stringify(data))
    },
    [types.UPDATE_CRAD_DETAILS](state, data) {
        window.localStorage.setItem(types.UPDATE_CRAD_DETAILS, JSON.stringify(data))
        state.cardDetails = data
    },
    [types.UPDATE_CRAD_ID](state, data) {
        window.localStorage.setItem(types.UPDATE_CRAD_ID, data)
        state.cardId = data
    },
    [types.UPDATE_USER_INFO](state, data) {
        window.localStorage.setItem(types.UPDATE_USER_INFO, JSON.stringify(data))
        state.userInfo = data
    },
    [types.UPDATE_ORDER_DETAILS](state, data) {
        window.localStorage.setItem(types.UPDATE_ORDER_DETAILS, JSON.stringify(data))
        state.orderDetails = data
    },
    [types.UPDATE_ORDER_ID](state, data) {
        window.localStorage.setItem(types.UPDATE_ORDER_ID, data)
        state.orderId = data
    },
    [types.UPDATE_BUY_HISTORY](state, data) {
        window.localStorage.setItem(types.UPDATE_BUY_HISTORY, data)
        state.buyHistory = data
    },
    [types.UPDATE_RECOMMENDEDS](state, data) {
        window.localStorage.setItem(types.UPDATE_RECOMMENDEDS, data)
        state.recommendeds = data
    },
    [types.UPDATE_ERR_CODES](state, data) {
        window.localStorage.setItem(types.UPDATE_ERR_CODES, data)
        state.errCodes = data
    },
    [types.UPDATE_CUR_SCHOOL](state, data) {
        window.localStorage.setItem(types.UPDATE_CUR_SCHOOL, data)
        state.curSchool = data
    },
    
}