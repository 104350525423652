<template>
    <div :class="className">
        <div v-if="leftBtn.hidden" class="left-btn" @click="leftHandler">
            <svg-icon class="btn" :icon-class="leftBtn.icon" />
            <span>{{ leftBtn.text }}</span>
        </div>
        <div class="title">{{ title }}</div>
        <div v-if="rightBtn.hidden" class="right-btn" @click="rightHandler">
            <svg-icon class="btn" :icon-class="rightBtn.icon" />
            <span>{{ rightBtn.text }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "NarBar",
    props: {
        className: {
            type: String,
            default: 'nar-bar-fixed'
        },
        leftBtn: {
            type: Object,
            default: () => {
                return {
                    hidden: false,
                    icon: '',
                    text: ''
                }

            }
        },
        title: {
            type: String,
            default: ''
        },
        rightBtn: {
            type: Object,
            default: () => {
                return {
                    hidden: false,
                    icon: '',
                    text: ''
                }
            }
        }
    },
    data() {
        return {

        };
    },
    activated() {
        // 使用keep-alive  此函数生效
    },
    methods: {
        leftHandler() {
            this.$emit('leftHandler')
        },
        rightHandler() {
            this.$emit('rightHandler')
        },
    },
};
</script>
<style lang="less" scoped>
.nar-bar-fixed {
    width: 100%;
    height: 50px;
    background: #F5F5F5;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
        height: 22px;
        font-size: 16px;
        font-weight: 500;
        color: #4A4A4A;
        line-height: 22px;
    }

    .left-btn {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
            cursor: pointer;
            font-size: 16px;
            vertical-align: middle;
        }
    }

    .right-btn {
        width: 50px;
        height: 50px;
        position: absolute;
        right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
            cursor: pointer;
            font-size: 16px;
            vertical-align: middle;
        }
    }
}

.nar-bar-block {
    width: 100%;
    height: 50px;
    background: #F5F5F5;
    // position: relative;
    // z-index: 100;
    // left: 0;
    // top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
        height: 22px;
        font-size: 16px;
        font-weight: 500;
        color: #4A4A4A;
        line-height: 22px;
    }

    .left-btn {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
            cursor: pointer;
            font-size: 16px;
            vertical-align: middle;
        }
    }

    .right-btn {
        width: 50px;
        height: 50px;
        position: absolute;
        right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
            cursor: pointer;
            font-size: 16px;
            vertical-align: middle;
        }
    }
}
</style>