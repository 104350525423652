export default {
    UPDATE_CRAD_DETAILS: 'UPDATE_CRAD_DETAILS',
    UPDATE_CRAD_ID: 'UPDATE_CRAD_ID',
    UPDATE_SKU: 'UPDATE_SKU',
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    UPDATE_ORDER_DETAILS: 'UPDATE_ORDER_DETAILS',
    UPDATE_ORDER_ID: 'UPDATE_ORDER_ID',
    UPDATE_BUY_HISTORY:'UPDATE_BUY_HISTORY',
    UPDATE_RECOMMENDEDS:'UPDATE_RECOMMENDEDS',
    UPDATE_ERR_CODES:'UPDATE_ERR_CODES',
    UPDATE_CUR_SCHOOL:'UPDATE_CUR_SCHOOL'
}