<template>
    <div class="bottom-bar">
        <div class="btn-item" v-for="(item, index) in btns" :key="index" @click="handler(item)">
            <svg-icon v-if="item.icon" :class="['icon', activeNumber === item['index'] ? 'active' : '']"
                :icon-class="item.icon" />
            <span v-if="item.text" :class="['text', activeNumber === item['index'] ? 'active-text' : '']">{{ item.text
            }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: "BottomBar",
    props: {
        btns: {
            type: Array,
            default: () => []
        },
        activeNumber: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {

        };
    },
    activated() {
        // 使用keep-alive  此函数生效
    },
    methods: {
        handler(item) {
            this.$emit('active', item)
        }
    },
};
</script>
<style lang="less" scoped>
.bottom-bar {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 65px;
    background: #fff;
    position: fixed;
    z-index: 100;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-item {
        flex: 1 1;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            cursor: pointer;
            font-size: 25px;
            color: #aaa
        }

        .text {
            cursor: pointer;
            font-size: 14px;
            color: #aaa
        }

        .active {
            color: #F29000;
        }

        .active-text {
            color: #222222;
        }
    }
}
</style>