import { get, post, put, deleteFn } from './requset'
const middle = 'h5/v1/'


const fromat = (url) => {
    return middle + url
}
/**
 * 登录
 * @param {*} params 
 * @returns 
 */
export const login = (params) => {
    return post(fromat('spus'), params)
}
/**
 * 验证token并登录
 * @param {*} params 
 * @returns 
 */


export const getToken = () => {
    return post(fromat('check/token'))
}
/**
 * 登出
 */
export const loginOut = () => {
    // 清除token
}


/**
 * 获取商品列表
 * @param {current_page:Number,page_size:bumber} params 
 * @returns {
 *    id:String,
 *    title:String,
 *    path:String,
 *    desc:String
 * }
 */
export const getSpusList = (params) => {
    return get(fromat('spus'), params)
}
/**
 * 获取单个商品信息
 * @param {spu_id:String } params
 * @returns  {
 *    path: require('../assets/card2.png'),
 *    title: '迷你卡面',
 *    desc: '让生活每一处，充满新生的气息',
 *    author: '新中新官网',
 *    online_at: '2022-10-26',
 *    id: '10033229607313',
 *    skus:【{
 *      id:String,
 *      price:35.5,
 *      path:String,
 *      name:'标准卡'
 *      attr:[{orgLabel:'材质',value:'标准卡'}]
 *    },{
 *      id:String,
 *      price:35.5,
 *      path:String,
 *      name:'背胶小卡'
 *      attr:[{orgLabel:'材质',value:'标准卡'}]
 *    }],
 * }
 */
export const getSpusOne = (params) => {
    return get(fromat(`spus/${params}`))
}
/**
 * @param {spu_id:String}
 * @return [{
 *   orgLabel:String,
 *   value:['string']
 *  }
 * ]
 *    
 */
export const getSpuOrg = (params) => {
    return get(fromat(`orgs/${params}`))
}

/**
 * 通过 spu + 规格 获取sku
 * @param {spu_id:String,
 *   attr:{
 *    orgLabel:'材质',
 *    value:'背胶小卡'
 * }       
 * } params 
 * @returns {
 *      id:String,
 *      price:35.5,
 *      path:String,
 *      attr:[{orgLabel:'材质',value:'标准卡'}]
 *  }
 */
export const getSdkByOrgs = (id, params) => {
    return post(fromat(`spus/${id}/sku`), params)
}

/**
 * 获取用户信息
 * @returns {
 *  "bankcard": "string",
 *  "cardno": "string",
 *  "id": 0,
 *  "name": "string",
 *  "org_id": 0,
 *  "org_title": "string",
 *  "sno": "string",
 *  "wechatopenid": "string",
 *  "xwx_school_id": "string"
 *  }
 */
export const getUserInfo = () => {
    return get(fromat('user'))
}

/**
 * 创建订单
 */
export const createOrder = (params) => {
    return post(fromat('user/orders'), params)
}
/**
 * 查询订单列表
 */
export const getOrderList = (params) => {
    return get(fromat('user/orders'), params)
}
/**
 * 查询订单详情
 */
export const getOrderById = (params) => {
    return get(fromat(`user/orders/${params}`))
}

/**
 * 获取购买记录
 */

export const getBuyList = (params) => {
    return get(fromat(`spus/${params['spu_id']}/skus/${params['sku_id']}/buy_histories`))
}

/**
 * 获取推荐商品列表
 */
export const getRecommended = (params) => {
    return get(fromat(`spus/${params}/recommended`))
}
/**
 * 设置收藏
 */
export const setCollection = (params) => {
    return post(fromat(`user/spus/${params['spu_id']}/skus/${params['sku_id']}/collections`),)
}


export const deleteCollection = (params) => {
    return deleteFn(fromat(`user/spus/${params['spu_id']}/skus/${params['sku_id']}/collections/${params['collection_id']}`),)
}
/**
 * 设置收藏
 */
export const getCollections = (params) => {
    console.log("🚀 ~ file: api.js ~ line 161 ~ setCollection ~ params", params)
    return get(fromat(`user/collections`), params)
}


/**
 * 手机短信登录认证
 */
export const phoneSmsLogin = (params) => {
    return post(fromat(`user/s_access_token`), params)
}
/**
 * 手机密码登录认证
 */
export const phonePwdLogin = (params) => {
    return post(fromat(`user/p_access_token`), params)
}
/**
 * 获取sms
 */
export const sendSms = (params) => {
    return post(fromat(`sms_captchas`), params)
}
/**
 * errorCode
 */
export const errCodes = () => {
    return get(fromat(`err_codes`))
}

/**
 * 获取学校信息
 */
export const getSchool = () => {
    return get(fromat(`orgs`))
}

/**
 * 创建用户
 */
 export const createUser = (params) => {
    return post(fromat(`users`), params)
}

/**
 * 更新用户信息
 */
 export const updateUser = (params) => {
    return put(fromat(`user`), params)
}