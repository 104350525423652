<template>
    <div class="card-item" @click="handler">
        <div class="connect">
            <div class="img-out">
                <img class="image" :src="item['cover']" alt="">
            </div>
            <div class="info-aera">
                <div class="card-title">{{ item['title'] }}</div>
                <div class="card-desc">{{ item['intro_text'] }}</div>
            </div>

        </div>
    </div>
</template>
<script>
import img from '../../assets/logo.png'
export default {
    name: "CardItem",
    props: {
        item: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            img
        };
    },
    activated() {
        // 使用keep-alive  此函数生效
    },
    methods: {
        handler() {
            this.$emit('choose', this.item)
        },
    },
};
</script>
<style lang="less" scoped>
.card-item {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5px 0;

    .connect {
        width: 172px;
        height: 230px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6px;

        .img-out {
            width: 100%;
            height: 170px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .image {
                border-radius: 4px;
                width: 170px;
                height: 170px;
            }
        }


        .info-aera {
            width: 100%;
            height: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .card-title {
                margin-top: 10px;
                width: 80%;
                height: 20px;
                font-size: 14px;
                line-height: 20px;
                text-align: left;
                overflow: hidden; //溢出隐藏
                white-space: nowrap; //禁止换行
                text-overflow: ellipsis;
            }

            .card-desc {
                width: 80%;
                // height: 17px;
                font-size: 12px;
                // line-height: 17px;
                text-align: left;
                color: #9B9B9B;
                overflow: hidden; //溢出隐藏
                white-space: nowrap; //禁止换行
                text-overflow: ellipsis;
            }
        }

    }

}
</style>