export default {
    common: '欢迎使用本模版',
    cardDetails: {},
    orderDetails: {},
    orderId: null,
    cardId: null,
    sku: {},
    userInfo: {},
    buyHistory:[],
    recommendeds:[],
    curSchool:{}
}