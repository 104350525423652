import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import axios from 'axios';
import routes from './router' // 获取路由设置
import 'amfe-flexible'
import './icons'  // 导入svg 图标文件
import './style/theme.css'  //  默认主题样式
import './style/style.css'  //  公共样式
import store from './store' // 引入store 状态管理
import debounce from './mixins/debounce';// 获取混入函数
import { Swipe, SwipeItem, Lazyload, Toast } from 'vant';
import 'vant/lib/index.less';
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Toast);

const router = new VueRouter({
  routes
})
Vue.mixin(debounce)
Vue.use(VueRouter)
/**
 * APP_BASE_API
 * 获取后台API域名
 */
const APP_BASE_API = document
  .querySelector("body")
  .getAttribute("VUE_APP_BASE_API");
if (APP_BASE_API !== null) {
  axios.defaults.baseURL = `${APP_BASE_API}`;
  Vue.prototype.$base_url = `${APP_BASE_API}`;
} else {
  Vue.prototype.$base_url = '';
  axios.defaults.baseURL = ``;
}
Vue.config.productionTip = false
/**
 * 全局路由导航
 */
router.beforeEach((to, from, next) => {
  console.log(from, to, 'ddddd')
  if (to['name'] === 'Home') {
    next();
  }
  if (to['name'] === 'LoginPage' || from['name'] === 'LoginPage') {
    if (to['name'] === 'BindPage') {
      next();
    } else {
      next();
    }
  }
  if (from['name'] === 'BindPage' || from['name'] === 'SchollList') {
    if (to['name'] === 'SchollList' || to['name'] === 'Home' || to['name'] === 'CardDetails') {
      next();
    }
    if (to['name'] === 'BindPage') {
      next();
    }
  }

  if (from['name'] === 'Home') {
    if (to['name'] === 'OrderList' || to['name'] === 'CardDetails' || to['name'] === 'BindPage' || to['name'] === 'CollectionList') {
      next();
    }
  } else if (from['name'] === 'OrderList') {
    if (to['name'] === 'Home' || to['name'] === 'OrderDetails') {
      next();
    }
  } else if (from['name'] === 'OrderDetails') {
    if (to['name'] === 'OrderList') {
      next();
    } else {
      next({ name: 'Home', path: '/' })
    }
  } else if (from['name'] === 'CardDetails') {
    if (to['name'] === 'OrderConfirm') {
      next();
    }
  } else if (from['name'] === 'OrderConfirm') {
    if (to['name'] === 'CardDetails' || to['name'] === 'OrderDetails' || to['name'] === 'CollectionList') {
      next();
    }
  } else if (from['name'] === 'CollectionList') {
    if (to['name'] === 'OrderConfirm') {
      next();
    }
  } else if (from['name'] === null) {
    const t = window.localStorage.getItem('LOGIN_TOKEN')
    if (t && t !== null) {
      window.localStorage.setItem('LOGIN_TOKEN', t)
    }
    next({ name: 'Home' })
  }

})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

