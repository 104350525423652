<template>
    <div class="my-info">
        <div class="info">
            <div class="user-infos">
                <svg-icon class="user-image" icon-class="user" />
                <div class="context">
                    <div class="user-name">{{ userInfo['name'] }}</div>
                    <div class="user-edit" @click="toEditHandler">
                        <svg-icon class="edit-style" icon-class="edit" />
                        <div>修改</div>
                    </div>
                </div>
            </div>
            <div class="bottom-area">
                <div class="item">
                    <div class="title">学校</div>
                    <div class="desc">{{ userInfo['org_title'] ? userInfo['org_title'] : '暂无' }}</div>
                </div>
                <div class="item">
                    <div class="title">学/工号</div>
                    <div class="desc">{{ userInfo['sno'] }}</div>
                </div>
            </div>
        </div>
        <div class="user-order-recomm">
            <div class="user-items" @click="nextPage('order')">
                <div class="left">
                    <svg-icon class="items-style" icon-class="order" />
                    <div>我的订单</div>
                </div>
                <div class="right">
                    <svg-icon class="items-style" icon-class="next" />
                </div>
            </div>
            <div class="user-items" @click="nextPage('collection')">
                <div class="left">
                    <svg-icon class="items-style" icon-class="collection" />
                    <div>我的收藏</div>
                </div>
                <div class="right">
                    <svg-icon class="items-style" icon-class="next" />
                </div>
            </div>
            <div class="login-out" @click="loginOut">
                <div class="btn">退出/切换账号</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "MyInfo",
    props: {
        userInfo: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
        };
    },
    activated() {
    },
    methods: {
        toEditHandler() {
            console.log('1111')
            this.$router.replace({
                name: 'BindPage',
                params: {
                    name: 'Home',
                    number: 1,
                    type: 'edit'
                }
            })
        },
        loginOut() {
            window.localStorage.setItem('LOGIN_TOKEN', '')
            window.localStorage.setItem('RESERVE_SWITCH', '')
            location.reload();
        },
        nextPage(type) {
            this.$emit('next-page', type)
        },

    },
};
</script>
<style lang="less" scoped>
.my-info {
    margin-top: 50px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #F6F7FA;

    .info {
        flex: 0 201px;
        width: 100%;
        height: 201px;
        display: flex;
        flex-direction: column;
        background: url('../../assets/user-bg.png');
        background-size: 100%;
        position: relative;

        .user-infos {
            margin-top: 30px;
            width: 100%;
            display: flex;
            flex-direction: row;

            .user-image {
                margin-left: 22px;
                margin-right: 14px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                border: 3px solid #FFFFFF;
                background: rgba(204, 204, 204, 0.512);
            }

            .context {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .user-name {
                    height: 22px;
                    font-size: 22px;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 20px;
                    margin-bottom: 10px;
                }

                .user-edit {
                    display: flex;
                    align-items: center;
                    height: 21px;
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 25px;

                    .edit-style {
                        width: 18px;
                        height: 18px;
                        margin-right: 5px;
                    }
                }
            }
        }

        .bottom-area {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            padding-bottom: 10px;

            .item {
                margin: 0 auto;
                width: 86%;
                height: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                    font-size: 14px;
                    font-weight: 400;
                    color: #555763;
                    line-height: 27px;
                }

                .desc {
                    font-size: 14px;
                    font-weight: 500;
                    color: #555763;
                    line-height: 27px;
                }
            }
        }
    }

    .user-order-recomm {
        margin-top: 15px;
        width: 100%;
        height: calc(100vh - 215px);
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;

        .user-items {
            width: 90%;
            height: 50px;
            margin: 5px auto;
            background: #FFFFFF;
            border-bottom: 1px solid #F6F7FA;
            display: flex;
            flex-direction: row;

            .left {
                height: 100%;
                display: flex;
                align-items: center;

                .items-style {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                }

                div {
                    font-size: 14px;
                    font-weight: 400;
                    color: #555763;
                    line-height: 20px;
                }
            }

            .right {
                margin-left: auto;
                margin-right: 5px;
                height: 100%;
                display: flex;
                align-items: center;

                .items-style {
                    height: 16px;
                    color: #BEBEBE;
                }
            }
        }

        .login-out {
            margin-top: 50px;
            width: 300px;
            height: 40px;
            border-radius: 20px;
            border: 1px solid #EB661B;
            display: flex;
            justify-content: center;
            align-items: center;

            .btn {
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #EB661B;
                line-height: 20px;
            }
        }
    }




}
</style>