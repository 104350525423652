import types from './type'
export default {
    updateCardDetails({ commit }, data) {
        commit(types.UPDATE_CRAD_DETAILS, data)
    },
    updateCardId({ commit }, data) {
        commit(types.UPDATE_CRAD_ID, data)
    },
    updateSku({ commit }, data) {
        commit(types.UPDATE_SKU, data)
    },
    updateUserInfo({ commit }, data) {
        commit(types.UPDATE_USER_INFO, data)
    },
    updateOrderDetails({ commit }, data) {
        commit(types.UPDATE_ORDER_DETAILS, data)
    },
    updateOrderID({ commit }, data) {
        commit(types.UPDATE_ORDER_ID, data)
    },
    updateBuyHistory({ commit }, data) {
        commit(types.UPDATE_BUY_HISTORY, data)
    },
    updateRecommended({ commit }, data) {
        commit(types.UPDATE_RECOMMENDEDS, data)
    },
    updateErrCode({ commit }, data) {
        commit(types.UPDATE_ERR_CODES, data)
    },
    updateCurSchool({ commit }, data) {
        commit(types.UPDATE_CUR_SCHOOL, data)
    },
    
}